import buildSelect2 from './algolia-select2'
import CONFIG from "./credentials";

/**
 * City placeholder
 * @type {string}
 */
const CITY_PLACEHOLDER = 'Seleziona un comune';
/**
 * Used to format a city result from Algolia
 * @param city
 * @returns {{data, id: number, text: *}}
 */
const mapResult = (city) => ({id: parseInt(city.objectID), text: city.name, data: city});

/**
 * Format a result in dropdown
 * @param city a city result
 * @returns {string}
 */
const cityTemplateResult = city => {
    if (city.loading === true) return city.text;
    // Template returned for rich text results
    let $result = $('<div>', {class: 'select2-user-result'}).text(`${city.data._highlightResult.name.value} (${city.data.province}) - ${city.data.region}`);
    // If matched fraction append it
    if (city.data._highlightResult.name.matchLevel === 'none') {
        const matchedFraction = _.find(city.data._highlightResult.fractions, f => f.matchLevel !== 'none');
        return `
            <div class='select2-user-result'>${city.data._highlightResult.name.value} (${city.data.province}) - ${city.data.region}
                <br />
                <small class="text-muted">${matchedFraction.value}, frazione di ${city.data.name}</small>
            </div>`;
    }
    // Else return just city name with no fraction
    return `<div class='select2-user-result'>${city.data._highlightResult.name.value} (${city.data.province}) - ${city.data.region}</div>`;
};

/**
 * Format a result after selection
 * @param city
 * @returns {string}
 */
const cityTemplateSelection = city => city.loading === true ? city.text : (city.data ? `${city.data._highlightResult.name.value} (${city.data.province}) - ${city.data.region}` : city.text);

buildSelect2('.city-selector', {
    indexName: CONFIG.CITY_INDEX,
    placeHolder: CITY_PLACEHOLDER,
    mapResult: mapResult,
    templateSelection: cityTemplateSelection,
    templateResult: cityTemplateResult,
});
