import './algolia-select2.scss'
import $ from 'jquery'
import _ from 'lodash';

import algoliasearch from 'algoliasearch/lite';
import 'select2/dist/js/select2'
import CONFIG from './credentials'

/**
 * Apply select2 to the given selector
 * 
 * @param selector
 * @param options
 */
export default (selector, options) => {

    // Initialize algolia
    const client = algoliasearch(CONFIG.APPLICATION_ID, CONFIG.SEARCH_API_KEY);
    const index = client.initIndex(options.indexName);

    $(function () {
        $(selector).each((idx, el) => {
            // See https://github.com/select2/select2/issues/4871#issuecomment-306700381
            let $select = $(el);
            let $modal = $select.closest('div.modal');
            let pageSize = $select.data('page-size') || 20;

            let select2Options = {
                dropdownParent: $modal.length ? $modal : null,
                placeholder: {text: options.placeHolder},
                ajax: {
                    delay: 100,
                    // wait stop typing before triggering the request
                    transport(params, success) {
                        const searchOptions = {
                            hitsPerPage: params.data.hitsPerPage,
                            page: params.data.page,
                        };
                        index.search(params.data.query, searchOptions).then((data) => success(params.results(data)));
                    },
                    /**
                     * build Algolia's query parameters
                     * @param term {{page: number, term: string, _type: string}}
                     * @returns {{hitsPerPage: (jQuery|number), query: *, page: *}}
                     */
                    data: (term) => ({query: term.term, hitsPerPage: pageSize, page: (term.page || 1) - 1}),
                    results: (data) => {
                        return {
                            results: data.hits.map(options.mapResult),
                            pagination: {more: data.page + 1 < data.nbPages}
                        };
                    }
                },
                templateSelection: options.templateSelection,
                templateResult: options.templateResult,
                escapeMarkup: _.identity,
                minimumInputLength: 2,
                language: {
                    errorLoading() {
                        return "I risultati non possono essere caricati."
                    }, inputTooLong(e) {
                        var n = e.input.length - e.maximum, t = "Per favore cancella " + n + " caratter";
                        return t += 1 !== n ? "i" : "e"
                    }, inputTooShort(e) {
                        return "Per favore inserisci " + (e.minimum - e.input.length) + " o più caratteri"
                    }, loadingMore() {
                        return "Caricando più risultati…"
                    }, maximumSelected(e) {
                        var n = "Puoi selezionare solo " + e.maximum + " element";
                        return 1 !== e.maximum ? n += "i" : n += "o", n
                    }, noResults() {
                        return "Nessun risultato trovato"
                    }, searching() {
                        return "Sto cercando…"
                    }, removeAllItems() {
                        return "Rimuovi tutti gli oggetti"
                    }
                },
            };
            // Init the select2 object
            $select.select2(select2Options);
        })
    });
}

