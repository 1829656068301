import jQuery from 'jquery';
import {map_url_staff_transport_path, map_url_route_path, dashboard_line_staff_route_path} from "../../routes";

jQuery(function () {
    $('img.route-static-map').each(function () {
        const $this = $(this);
        $.getJSON(map_url_route_path($this.data('id')))
            .then(data => $this.attr('src', data.map_url));
    });

    $('img.transport-static-map').each(function () {
        const $this = $(this);
        $.getJSON(map_url_staff_transport_path($this.data('id')))
            .then(data => $this.attr('src', data.map_url));
    });

    // Set format to download
    $('.excel-report').click(function () {
        const $format = $('<input/>', {type: 'hidden', name: 'format', value: 'xlsx'});
        const $form = $(this).closest('form');
        $form.closest('form').append($format).submit();
        const enableAgain = function () {
            $format.remove();
            $.rails.enableFormElements($form);
        };
        setTimeout(enableAgain, 1500);
    });
});

// Replace the whole line with a new copy rendered server side
const reloadRow = $line => $.get(dashboard_line_staff_route_path($line.data('id'))).then(function (line) {
    const $newLine = $(line);
    $newLine.find('[data-toggle="popover"]').popover({sanitize: false});
    return $line.replaceWith($newLine);
});

jQuery(function () {
    // Close note form on cancel buttons
    $(document).on('click', '.note_form_route button[type=reset], .date_form_route button[type=reset]', function () {
        // Use the icon that triggered the popover to trigger the popoover close
        $(this).closest('.popover').data('bs.popover').$element.trigger('click');
    });
    // When note is saved with success
    $(document).on('ajax:success', '.note_form_route', function () {
        // Destroy popover
        $(this).closest('.popover').popover('destroy');
        // Show a notification
        $.notify({
            message: 'La nota è stata aggiunta alla corsa',
            icon: 'glyphicon glyphicon-ok-sign'
        }, {type: 'success'});
        reloadRow($(this).closest('tr'));
    });

    $(document).on('ajax:error', '.note_form_route', () => $.notify({
        message: 'Non puoi aggiungere una nota vuota',
        icon: 'glyphicon glyphicon-alert'
    }, {type: 'danger'}));

    // When a date is saved with success
    $(document).on('ajax:success', '.date_form_route', function () {
        // Destroy popover
        $(this).closest('.popover').popover('destroy');
        // Show a notification
        $.notify({message: 'La data è stata aggiornata', icon: 'glyphicon glyphicon-ok-sign'}, {type: 'success'});
        // Replace the whole line with a new copy rendered server side
        reloadRow($(this).closest('tr'));
    });

    $(document).on('ajax:error', '.note_form_route', () => $.notify({
        message: 'Non sono riuscito a salvare la data',
        icon: 'glyphicon glyphicon-alert'
    }, {type: 'danger'}));
});
