import './jquery.blockUI'

// Global jquery object
const $ = window.jQuery;

/**
 * Block whole dom, release with $.unblockUI
 */
export function blockUI() {
    $.blockUI({
        overlayCSS: {
            background: 'rgba(142, 159, 167, 0.3)',
            opacity: 1,
            cursor: 'wait'
        },
        css: {
            width: 'auto',
            top: '45%',
            left: '45%'
        },
        message: '<div class="blockui-default-message">Loading...</div>',
        blockMsgClass: 'block-msg-message-loader'
    });
    // setTimeout($.unblockUI, 2000);
}

/**
 * Block a single element with the given message
 * @param $elt element to block
 * @param message message to use in loader
 */
export function block($elt, message = 'We are processing your request. <br> Please be patient.') {
    $elt.block({
        message: `<div class="blockui-default-message"><i class="fa fa-circle-o-notch fa-spin"></i><h6>${message}</h6></div>`,
        overlayCSS: {
            background: 'rgba(142, 159, 167, 0.8)',
            opacity: 1,
            cursor: 'wait'
        },
        css: {
            width: '50%'
        },
        blockMsgClass: 'block-msg-default'
    });
}

$(function () {
    /**
     * Elements with this class will block their parent (or element specified in data-block)
     */
    $(document).on('click', '.block-ui', (event) => {
        if ($(event.target).data('block')) {
            block($($(event.target).data('block')))
        } else {
            block($(event.target).parent());
        }
    });

    // Block stripe payment form until redirect
    $('#pay_route').on('ajax:beforeSend', (elt) => {
        block(($(elt.target)), `Attendi mentre vieni reindirizzato alla pagina di pagamento`);
    });
});
