import CONFIG from './credentials'
import buildSelect2 from './algolia-select2'

buildSelect2('.bike-selector', {
    indexName: CONFIG.BIKE_INDEX,
    placeHolder: 'Seleziona una moto',
    mapResult: (h) => ({id: parseInt(h.objectID), text: h.modello, data: h}),
    templateSelection: bike => bike.loading === true ? bike.text : (bike.data ? bike.data.formatted_name : bike.text),
    templateResult: function (bike) {
        if (bike.loading === true) {
            return bike.text;
        } else {
            return `
        <div class='select2-user-result'>${bike.data._highlightResult.marca.value} ${bike.data._highlightResult.modello.value}
            <br />
            <small class="text-muted">Cilindrata: ${bike.data.cilindrata} - Ingombro: ${bike.data.ingombro}</small>
        </div>`;
        }
    },
});
