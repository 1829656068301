import jQuery from 'jquery';
import {route_activity_path} from "../../routes";

jQuery(function() {

  // Delete notes using remote ajax
  $('.edit_public_activity_activity').bind('close.bs.alert', function(e){
    e.preventDefault();
    if (confirm("Sei sicuro di voler cancellare questa nota? L'operazione è irreversibile.")) {
      const $this = $(this);
      const $parent = $this.closest('.activity-line-item');
      // Remove the whole block if is the last item for that date
      const $to_remove = $parent.find('.edit_public_activity_activity').length > 1 ? $this : $parent;
      $.ajax({
        type: "DELETE",
        url: route_activity_path($this.data('route-id'), $this.data('id')),
        dataType: "json"
      }).then(() => $to_remove.fadeOut('slow', null, () => $to_remove.remove()));
    }
  });

  $(document).on('click', '.edit_public_activity_activity .edit-note-text', function() {
    $(this).closest('.alert').find('form').fadeIn('fast');
  });

  $(document).on('click', '.edit_public_activity_activity .btn-cancel', function() {
      $(this).closest('form').fadeOut('fast');
  });
});
