import $ from 'jquery'
import {feature_user_path} from "../../routes";

jQuery(() => $('body.users.show .checkbox-toggle').change(function () {
    const $cb = $(this).find('input');
    const userId = $(this).data('user');
    const name = $(this).data('name');
    const isChecked = $cb.is(':checked');
    const featureName = $cb.attr('id');
    console.debug("Feature", featureName, 'was set to', isChecked, 'for user', userId);
    jQuery.ajax({
        url: feature_user_path(userId),
        method: 'PUT',
        data: {
            feature: {
                name: featureName,
                value: isChecked ? 1 : null
            }
        }
    }).then(() => {
        let message = isChecked ?
            `La funzionalità ${featureName} è stata attivata per l'utente ${name}` :
            `La funzionalità ${featureName} è stata disattivata per l'utente ${name}`;
        jQuery.notify(message);
    });
}));
