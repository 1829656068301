/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.8.4 routes of MotohelpRails::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /staff/transports/:id/accept_cancellation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_cancellation_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accept_cancellation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/accept(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /nemo/transports/accepting_count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accepting_count_nemo_transports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"nemo"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"accepting_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/acknowledge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acknowledge_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"acknowledge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /staff/transports/:id/attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachment_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/validate_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_validate_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"validate_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /truck_availabilities/availabilities_week(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const availabilities_week_truck_availabilities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"truck_availabilities"],[2,[7,"/"],[2,[6,"availabilities_week"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff/routes/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_staff_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoice_data/by_owner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_owner_invoice_data_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_data"],[2,[7,"/"],[2,[6,"by_owner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calendar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /checkouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /checkouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /routes/:id/clear_journeys(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_journeys_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clear_journeys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/timecards/:id/close_timecard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_timecard_user_timecard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"timecards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close_timecard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /todos/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_todo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"todos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tml/routes/:id/confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_tml_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tml"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/timecards/current(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const current_user_timecards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"timecards"],[2,[7,"/"],[2,[6,"current"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/routes/:id/dashboard_line(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_line_staff_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dashboard_line"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_staff_transports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/date_change(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const date_change_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"date_change"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /nemo/transports/:id/deliver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_nemo_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"nemo"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deliver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/deliver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deliver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_auth_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/discount(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const discount_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"discount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/driven(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const driven_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"driven"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/driver_dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const driver_dashboard_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"driver_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_auth_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/electronic_invoice(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const electronic_invoice_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"electronic_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /expiring_quotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expiring_quotes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"expiring_quotes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/export(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/feature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feature_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"feature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/fix_tracking(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fix_tracking_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fix_tracking"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /flipper
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const flipper_path = __jsr.r({}, [2,[7,"/"],[6,"flipper"]]);

/**
 * Generates rails route to
 * /routes/:id/for_driver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const for_driver_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"for_driver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gifts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /global_timeline(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_timeline_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"global_timeline"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphql_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_monitor_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * //heartbeat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_monitor_heartbeat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"heartbeat"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/impersonate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_data/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_data_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoice_data"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_data_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_data"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices/invoice_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_types_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"invoice_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/invoiceable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoiceable_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"invoiceable"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staff/transports/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices_staff_transports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/last(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const last_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"last"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:id/list_pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_pdf_truck_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_with_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /auth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_with_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:id/maintenance_attachments(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const maintenance_attachments_truck_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"maintenance_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/map_url(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_url_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"map_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/map_url(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_url_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"map_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const me_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /menubar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const menubar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"menubar"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /todos/menubar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const menubar_todos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"todos"],[2,[7,"/"],[2,[6,"menubar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/miss_delivery(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const miss_delivery_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"miss_delivery"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/miss_withdrawal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const miss_withdrawal_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"miss_withdrawal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/monthly_stats(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const monthly_stats_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"monthly_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize/native(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const native_oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"native"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nemo_api_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /transports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nemo_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /nemo/transports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nemo_transports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"nemo"],[2,[7,"/"],[2,[6,"transports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_invoice_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_oauth_application_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_route_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/next_invoice_number(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_invoice_number_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"next_invoice_number"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_introspect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_revoke_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/pay(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pay_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pay"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/payment_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_methods_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /paypal/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paypal_cancel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /paypal/process(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paypal_process_ipn_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"process"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /paypal/return(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paypal_return_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"return"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/permitted_events(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const permitted_events_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"permitted_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/post_checkout(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_checkout_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"post_checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/price_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const price_download_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"price_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /public/prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_prices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"prices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /public/quote(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_quote_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"quote"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /public/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pusher/auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pusher_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pusher"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /routes/quotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotes_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"quotes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:id/refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refund_checkout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/regional_prices_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regional_prices_upload_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"regional_prices_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/reject_cancellation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_cancellation_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reject_cancellation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/reset_password_link(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_password_link_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_password_link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /routes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/:route_id/activities(.:format)
 * @param {any} route_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_activities_path = __jsr.r({"route_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:route_id/activities/:id(.:format)
 * @param {any} route_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_activity_path = __jsr.r({"route_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"route_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /invoices/:id/send_by_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_by_email_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_by_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/send_feedaty_review(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_feedaty_review_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_feedaty_review"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/send_invoice(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_invoice_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sendgrid/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sendgrid_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/set_invoice_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_invoice_data_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_invoice_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /s/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortened_url_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"s"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_path = __jsr.r({}, [2,[7,"/"],[6,"jobs"]]);

/**
 * Generates rails route to
 * /routes/:id/sms_offer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_offer_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sms_offer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/routes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/stop_impersonating(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_impersonating_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop_impersonating"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /stripe/webhooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_webhooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stripe"],[2,[7,"/"],[2,[6,"webhooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/timeline(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const timeline_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"timeline"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tml/auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tml_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tml"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tml/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tml_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tml"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tml/routes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tml_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tml"],[2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /to_call(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_call_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"to_call"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /today(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const today_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"today"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /todos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const todo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"todos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /todos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const todos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"todos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /routes/:id/toggle_stripe(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_stripe_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/tracking_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_note_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tracking_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/tracking(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tracking"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /routes/:id/transport(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_route_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transport"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /truck_maintenances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const truck_maintenances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"truck_maintenances"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /trucks/:truck_id/truck_availabilities(.:format)
 * @param {any} truck_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const truck_truck_availabilities_path = __jsr.r({"truck_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"truck_id"],[2,[7,"/"],[2,[6,"truck_availabilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:truck_id/truck_availabilities/:id(.:format)
 * @param {any} truck_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const truck_truck_availability_path = __jsr.r({"truck_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"truck_id"],[2,[7,"/"],[2,[6,"truck_availabilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:truck_id/truck_maintenances/:id(.:format)
 * @param {any} truck_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const truck_truck_maintenance_path = __jsr.r({"truck_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"truck_id"],[2,[7,"/"],[2,[6,"truck_maintenances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:truck_id/truck_maintenances(.:format)
 * @param {any} truck_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const truck_truck_maintenances_path = __jsr.r({"truck_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"truck_id"],[2,[7,"/"],[2,[6,"truck_maintenances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trucks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/timecards/trucks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trucks_user_timecards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"timecards"],[2,[7,"/"],[2,[6,"trucks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/upload_excel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_excel_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:id/upload_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_file_truck_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /trucks/:truck_id/truck_maintenances/:id/upload_file(.:format)
 * @param {any} truck_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_file_truck_truck_maintenance_path = __jsr.r({"truck_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"trucks"],[2,[7,"/"],[2,[3,"truck_id"],[2,[7,"/"],[2,[6,"truck_maintenances"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/route_imports/:id(.:format)
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_route_import_path = __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"route_imports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/route_imports(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_route_imports_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"route_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/timecards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_timecards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"timecards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/vacations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vacations_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"vacations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/vat_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vat_codes_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"vat_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices/vat_register(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vat_register_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"vat_register"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/with_missing_delivery(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const with_missing_delivery_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"with_missing_delivery"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /routes/with_missing_withdraw(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const with_missing_withdraw_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"routes"],[2,[7,"/"],[2,[6,"with_missing_withdraw"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /nemo/transports/:id/withdraw(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const withdraw_nemo_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"nemo"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"withdraw"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/transports/:id/withdraw(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const withdraw_staff_transport_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"withdraw"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

