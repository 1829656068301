import 'jquery-match-height'

$(() => {
    let $pageCenter = $('.page-center');
    $pageCenter.matchHeight({target: $('html')});
    $(window).resize(() => {
        setTimeout(() => {
            $pageCenter.matchHeight({remove: true});
            $pageCenter.matchHeight({target: $('html')});
        }, 100);
    });
});
