/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// main application css files, referenced as application in layout
import '../styles/main.less'

// Global stylesheets
import 'font-awesome/css/font-awesome.css';
import 'notie/src/notie.scss';
window.notie = require('notie');
window.Clipboard = require('clipboard');

// Global application code extracted from template
import './matchHeight';
import './datepicker';
import './bootstrap-notify';
import './blockui';
import './algolia';
import './global';
