import jQuery from 'jquery'
// momentjs localization
import 'moment/locale/it';
import moment from 'moment';

moment.locale('it');
// @see http://www.daterangepicker.com/
import 'daterangepicker'
// Use same jquery version in script
const $ = jQuery

export const rangePickerLocale = {
    "format": "DD/MM/YYYY",
    "separator": " - ",
    "applyLabel": "Applica",
    "cancelLabel": "Cancella",
    "fromLabel": "Da",
    "toLabel": "A",
    "customRangeLabel": "Personalizzato",
    "weekLabel": "Set",
    "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Me",
        "Gi",
        "Ve",
        "Sa"
    ],
    "monthNames": [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
    ],
    "firstDay": 1
};

jQuery(() => {

    jQuery('body').on('focus', '.range-picker', function () {
        let $filterPickers = jQuery(this)
        // Enable datepicker for element only once
        if ($filterPickers.data('daterangepicker')) return;

        $filterPickers.daterangepicker({
            locale: rangePickerLocale,
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            drops: $filterPickers.data('drops') || 'down'
        });

        $filterPickers.on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY'));
        });

        $filterPickers.on('cancel.daterangepicker', function () {
            $(this).val('');
        });
    })
});
